import { HomeCopy, PlusTabs, PricingTable } from '@chegg-wt/core/types/core'
import { TYPE_CREATE_CITATIONS, TYPE_CHECK_PAPER } from '@chegg-wt/core/constants/card'
import {
  TYPE_CITATIONS,
  TYPE_EXPERT_CHECK,
  TYPE_GRAMMAR,
  TYPE_PLAGIARISM,
} from '@chegg-wt/core/constants/plusTab'
import {
  CREATE_CITATIONS_CONTEXT_ID,
  CHECK_PAPER_CONTEXT_ID,
} from '@chegg-wt/core/components/HeaderWithCards/testIds'
import {
  PRICING_TABLE_FREE,
  CHECK,
  PRICING_TABLE_PLUS,
  CITATION_STYLE,
  EXPERT_PAPER_HELP,
  GRAMMAR_CHECKS,
  PLAGIARISM_DETECTION,
  SAVE_CITATIONS,
  ADD_FREE_EXPERIENCE,
} from '@chegg-wt/core/constants/pricingTable'
import { Config } from '@chegg-wt/core/config'
import * as tabTestIds from '@chegg-wt/core/components/PlusTab/testIds'

export const cards: HomeCopy = {
  header: 'BibMe — The Online Writing Center',
  createCitations: {
    imgFilename: 'card-create-citations.svg',
    buttonText: 'Create citations',
    context: {
      value: 'Start a new citation or manage your existing bibliographies.',
      contextTestId: CREATE_CITATIONS_CONTEXT_ID,
    },
    dataTestId: 'create-citations-btn',
    type: TYPE_CREATE_CITATIONS,
  },
  checkPaper: {
    imgFilename: 'card-check-your-paper.svg',
    buttonText: 'Check your paper',
    context: {
      value: 'Scan your paper for plagiarism and grammar errors.',
      contextTestId: CHECK_PAPER_CONTEXT_ID,
    },
    dataTestId: 'check-paper-btn',
    type: TYPE_CHECK_PAPER,
  },
}

export const plusTabs: PlusTabs = {
  header: 'Take your paper to the next level',
  subHeader:
    'Have your paper checked for grammar errors, missing punctuation, unintentional plagiarism, and more!',
  tabs: [
    {
      label: 'citations',
      imgFilename: 'eb-tabs-citations.png',
      alt: 'cite using APA, MLA, Chicago and more',
      header: "Know you're citing correctly",
      to: '/apa',
      context:
        "No matter what citation style you're using (APA, MLA, Chicago, etc.) we'll help you create the right bibliography",
      dataTestId: tabTestIds.CITATIONS_TAB,
      type: TYPE_CITATIONS,
    },
    {
      label: 'plagiarism',
      imgFilename: 'eb-tabs-plagiarism.png',
      alt: 'check for potential plagiarism',
      header: 'Check for unintentional plagiarism',
      to: '/grammar-and-plagiarism/',
      context:
        'Scan your paper the way your teacher would to catch unintentional plagiarism. Then, easily add the right citation',
      dataTestId: tabTestIds.PLAGIARISM_TAB,
      type: TYPE_PLAGIARISM,
    },
    {
      label: 'expert check',
      imgFilename: 'eb-tabs-expert-check.png',
      alt: 'experts will proofread your paper',
      header: 'Strengthen your writing',
      to: '/grammar-and-plagiarism/',
      context:
        "Give your paper an in-depth check. Receive feedback within 24 hours from writing experts on your paper's main idea, structure, conclusion, and more.",
      dataTestId: tabTestIds.EXPERT_CHECK_TAB,
      type: TYPE_EXPERT_CHECK,
    },
    {
      label: 'grammar',
      imgFilename: 'eb-tabs-grammar.png',
      alt: 'check for grammar mistakes and writing issues',
      header: 'Find and fix grammar errors',
      to: '/grammar-and-plagiarism/',
      context:
        "Don't give up sweet paper points for small mistakes. Our algorithms flag grammar and writing issues and provide smart suggestions",
      dataTestId: tabTestIds.GRAMMAR_TAB,
      type: TYPE_GRAMMAR,
    },
  ],
}

export const oldPricingTableData: PricingTable = {
  title: 'How we’ll help improve your paper...',
  features: [
    { id: CITATION_STYLE, name: 'Citation styles' },
    { id: GRAMMAR_CHECKS, name: 'Grammar checks' },
    { id: SAVE_CITATIONS, name: 'Save your citations' },
    { id: PLAGIARISM_DETECTION, name: 'Plagiarism detection' },
    { id: EXPERT_PAPER_HELP, name: 'Expert help for your paper' },
  ],
  products: [
    {
      id: PRICING_TABLE_FREE,
      name: 'BibMe™ <br/> free Account',
      features: {
        citationStyle: '7000+ styles including APA & Chicago',
        grammarChecks: 'Only first 5 errors checked',
        saveCitations: CHECK,
      },
      price: 'Free',
    },
    {
      id: PRICING_TABLE_PLUS,
      name: 'BibMe™ Plus <br/> 3-day free trial*',
      features: {
        citationStyle: '7000+ styles including APA & Chicago',
        grammarChecks: 'Unlimited',
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        expertPaperHelp: '30 papers a month',
      },
      price: '$9.95/mo.',
      recommended: true,
    },
  ],
  termsAndConditionsUrl: Config.trialTermsAndConditionsUrl,
}

export const newPricingTableData: PricingTable = {
  features: [
    { id: CITATION_STYLE, name: 'Citation style' },
    { id: ADD_FREE_EXPERIENCE, name: 'Ad-free experience' },
    { id: PLAGIARISM_DETECTION, name: 'Plagiarism detection' },
    { id: EXPERT_PAPER_HELP, name: 'Expert help with your papers' },
    { id: GRAMMAR_CHECKS, name: 'Unlimited grammar check' },
    { id: SAVE_CITATIONS, name: 'Save citations' },
  ],
  products: [
    {
      id: PRICING_TABLE_FREE,
      name: 'Free account',
      price: 'Free',
      features: {
        citationStyle: 'APA only',
        grammarChecks: CHECK,
        saveCitations: 'Only for 7 days',
      },
    },
    {
      id: PRICING_TABLE_PLUS,
      name: 'BibMe™ Plus 3-day free trial*',
      price: '$9.95/mo.',
      recommended: true,
      features: {
        citationStyle: 'MLA, APA, Chicago, Harvard & 7,000 more',
        grammarChecks: CHECK,
        saveCitations: CHECK,
        plagiarismDetection: CHECK,
        adFreeExperience: CHECK,
        expertPaperHelp: '30 papers / month',
      },
    },
  ],
  termsAndConditionsUrl: Config.trialTermsAndConditionsUrl,
}
