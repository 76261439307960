import { Config } from '@chegg-wt/core/config'
import { Resource } from '@chegg-wt/core/types/core'

const resources: Resource[] = [
  {
    header: 'Complete Guide to MLA format',
    summary:
      'Everything you need to know about MLA format is in this guide. Brush up on MLA basics and break down citations for multiple source types each with a real-life citation example to help you learn.',
    dataTestId: 'mla-guide-link',
    url: `${Config.baseUrl}/mla`,
  },
  {
    header: 'Useful guide to APA format',
    summary:
      'Take the uncertainty out of citing in APA format with our guide. Review the fundamentals of APA format and learn to cite several different source types using our detailed citation examples.',
    dataTestId: '',
    url: `${Config.baseUrl}/apa`,
  },
  {
    header: 'Practical guide to Chicago syle',
    summary:
      "Using Chicago Style is easier once you know the fundamentals. This guide presents the base rules of Chicago Style along with citation examples for various source types. It'll give you a solid foundation to begin citing from.",
    dataTestId: '',
    url: `${Config.baseUrl}/citation-guide/chicago`,
  },
]

export default resources
